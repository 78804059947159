var datepicker = $.fn.datepicker.noConflict();
$.fn.bootstrapDP = datepicker;

$.fn.bootstrapDP.defaults.autoclose = true;
$.fn.bootstrapDP.defaults.maxViewMode = 2;
$.fn.bootstrapDP.defaults.format = "yyyy-mm-dd";

$(function () {
	$('.datepicker').bootstrapDP({});
});
