// See https://jqueryvalidation.org/files/demo/bootstrap/index.html

jQuery.validator.setDefaults({
	errorElement: "div",
	errorPlacement: function (error, element) {
		error.addClass("invalid-feedback");
		element.closest("div:not(.iti)").append(error);
	},
	highlight: function (element, errorClass, validClass) {
        element.setCustomValidity("invalid");
		$(element.form).find("[name='" + $(element).attr("name") + "']").removeClass("is-valid").addClass("is-invalid");
	},
	unhighlight: function (element, errorClass, validClass) {
        element.setCustomValidity("");
		$(element.form).find("[name='" + $(element).attr("name") + "']").removeClass("is-invalid").addClass("is-valid");
	},
	submitHandler: function (form) {
		if (this.wasSubmitted)  // Prevent multiple form submissions
			return;
		this.wasSubmitted = true;
		form.submit();
	}
});

$("form:not([novalidate])").each(function () {
	$(this).validate();
});
